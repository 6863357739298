<template>

	<div >
		<h3>导览组件 是一款全局组件，在需要使用的地方调用 this.guide() 方法进行使用</h3>

		<div>步骤编辑器</div>

		<div style="overflow:auto;">
			<div style="float: left">
				<table border="1">
					<tr>
						<td>步骤类型</td>
						<td>指示元素的id</td>
						<td>说明内容</td>
						<td>操作</td>
					</tr>
					<tr v-for="(item,index) in stepList">
						<td>
							<el-select v-model="item.type" placeholder="请选择">
								<el-option label="指示+说明" value="1"> </el-option>
								<el-option label="仅说明" value="2"> </el-option>
							</el-select>
						</td>
						<td><el-input v-model="item.elementId" placeholder="请输入内容" v-if="item.type==1"></el-input></td>
						<td><el-input v-model="item.infomation" placeholder="请输入内容"></el-input></td>
						<td>
							<span class="op sc hand" @click="stepList.splice(index,1)">删除</span>
						</td>
					</tr>
				</table>

				<div @click="add" class="op sc hand">添加一条步骤</div>
			</div>

			<div style="float: left; margin-left:30px;">
				<pre style="background: #2f2f2f;color: #FFFFFF;">this.guide({{stepList}});</pre>
			</div>

			<div style="float: left">
				<button @click="run">执行代码</button>
			</div>

		</div>

		<div id="test1" style="width: 100px;height: 200px;background: #1C7EFF;float: left">测试点1</div>

		<div id="test2" style="width: 200px;height: 100px;background: #00feff;margin: 100px;float: right">测试点2</div>
	</div>

</template>

<script>

export default {
	name: "test1",
	computed:{
		user() {
			return this.$store.state.user;
		},
		is_login() {
			return this.$store.state.is_login;
		},
	},
	data(){
		return{
			stepList:[{
				type:"2",
				infomation:"欢迎来到百工宝"
			},{
				type:"2",
				infomation:"下面由我来为您介绍一下我们的产品"
			},{
				type:"1",
				elementId:"test1",
				infomation:"这个是我们最新出的功能：“test1”，他很屌"
			},{
				type:"1",
				elementId:"test2",
				infomation:"这个是我们最新出的功能：“test2”，他也很屌"
			},{
				type:"2",
				infomation:"感谢聆听，祝您使用愉快"
			},]
		}
	},
	mounted() {

	},
	methods:{
		run(){
			this.guide(this.stepList);
		},
		add(){
			this.stepList.push({
				type:"1",
				elementId:"",
				infomation:""
			})
		}
	}
}
</script>

<style scoped>
</style>